<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Catégories de service</v-card-title>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(serviceCategory, serviceCategoryIndex) in serviceCategories"
              :key="'e' + serviceCategoryIndex"
            >
              <td class="font-weight-bold">
                {{ resolveAttrValue(serviceCategory, 'value') }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'serviceCategoryDetails', params: { serviceCategoryId: serviceCategory.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteServiceCategoryDialog(serviceCategory)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!serviceCategories.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import config from '../../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Loader
  },
  data: () => ({
    serviceCategories: [],
    serviceCategoryToDelete: null,
    deleteServiceCategoryDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getServiceCategories()
  },
  methods: {
    getServiceCategories () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/service/categories', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.serviceCategories = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    resolveAttrValue (data, attr) {
      const translations = data.translations

      if (translations.fr) {
        return translations.fr[attr]
      }

      return '-'
    }
  }
}
</script>

